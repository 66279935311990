<template>
  <div class="ion-padding ion-text-center">
    <ion-text style="font-size: 75%" color="medium">
      P&L (Profit and Loss)<br/>
      กราฟแสดง รายรับ หักกับ รายจ่าย ในแต่ละเดือน
    </ion-text>
  </div>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label>From</ion-label>
          <ion-datetime display-format="YYYY-MM" v-model="rangeFrom" :max="rangeTo"></ion-datetime>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item lines="none">
          <ion-label>To</ion-label>
          <ion-datetime display-format="YYYY-MM" v-model="rangeTo" :min="rangeFrom"></ion-datetime>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <bar-chart :chart-data="chartData" :options="chartOptions"></bar-chart>
</template>

<script>
import {
  IonCol,
  IonDatetime,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/vue";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { BarController, BarElement, CategoryScale, Chart, LinearScale, Tooltip } from "chart.js";
import { BarChart } from "vue-chart-3";

Chart.register(
  BarController,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
);

export default {
  name: "ChartProfitAndLoss",
  components: {
    BarChart,
    IonCol,
    IonDatetime,
    IonGrid,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
  },
  setup() {
    const store = useStore();

    const fromDate = new Date();
    fromDate.setFullYear(new Date().getFullYear() - 1);
    const rangeFrom = ref(fromDate.toISOString().substr(0, 7));
    const rangeTo = ref(new Date().toISOString().substr(0, 7));

    const chartData = computed(() => {
      const c = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };

      if (
        store.state.save.report_data &&
        store.state.save.report_data.monthly_summary
      ) {
        Object.keys(store.state.save.report_data.monthly_summary).forEach(
          (m) => {
            if (m >= rangeFrom.value && m <= rangeTo.value) {
              // รายชื่อเดือน
              c.labels.push(m);
              const sum = Object.keys(
                store.state.save.report_data.monthly_summary[m]
              ).reduce(
                (carry, cat_id) => {
                  if (!cat_id.match(/^\d+$/))
                    return carry;
                  else
                    return carry + store.state.save.report_data.monthly_summary[m][cat_id];
                }, 0
              );

              c.datasets[0].data.push(sum);
              if (sum > 0) {
                c.datasets[0].backgroundColor.push("darkgreen");
              } else {
                c.datasets[0].backgroundColor.push("red");
              }
            }
          }
        );
      }

      return c;
    });

    return {
      rangeFrom,
      rangeTo,
      chartData,
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
};
</script>

<style scoped></style>
