<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label>From</ion-label>
          <ion-datetime
            display-format="YYYY-MM"
            v-model="rangeFrom"
            :max="rangeTo"
          ></ion-datetime>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item lines="none">
          <ion-label>To</ion-label>
          <ion-datetime
            display-format="YYYY-MM"
            v-model="rangeTo"
            :min="rangeFrom"
          ></ion-datetime>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <bar-chart :chart-data="chartData" :options="options"></bar-chart>
  <!-- วิธีใช้งาน -->
  <div class="ion-padding">
    <ion-text color="medium">
      <ion-icon :ios="helpOutline" :md="helpSharp"></ion-icon>
      ชื่อหมวดหมู่ ถูกแสดงโดยเรียงจากจำนวนเงิน มาก&#10141;น้อย
      คุณสามารถคลิกที่ชื่อหมวดหมู่ เพื่อซ่อน หรือแสดงผล หมวดหมู่ในกราฟแท่งได้
    </ion-text>
  </div>
  <div class="ion-padding">
    <ion-text color="medium">
      <ion-icon :ios="helpOutline" :md="helpSharp"></ion-icon>
      คลิกที่แท่งกราฟ 2 ครั้ง เพื่อซูมดูค่าใช้จ่ายของเดือนนั้น
    </ion-text>
  </div>
  <div>
    <table class="cell-padding" style="margin: 2em auto">
      <thead>
        <tr>
          <th></th>
          <th>category</th>
          <th>summary</th>
          <th>average</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, key) in overviewOfEach" :key="key">
          <td :style="{ backgroundColor: item.color }">&nbsp; &nbsp;</td>
          <td>{{ item.label }}</td>
          <td class="ion-text-right">{{ item.summary }}</td>
          <td class="ion-text-right">{{ item.average }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td class="ion-text-right"><b>Period</b></td>
          <td class="ion-text-center">{{ displayRangeFrom }}</td>
          <td class="ion-text-center">{{ displayRangeTo }}</td>
        </tr>
        <tr>
          <td></td>
          <td class="ion-text-right"><b>Num of months</b></td>
          <td colspan="2" class="ion-text-center">{{ numOfMonths }}</td>
        </tr>
        <tr>
          <td></td>
          <td class="ion-text-right"><b>Total</b></td>
          <td class="ion-text-right">{{ totalSummary }}</td>
          <td class="ion-text-right">{{ totalAverage }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, toRef } from "vue";
import {
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  modalController,
} from "@ionic/vue";
import ModalChartMonthZoomExpense from "./ModalChartMonthZoomExpense.vue";
import { BarChart } from "vue-chart-3";
import {
  Chart,
  BarController,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
} from "chart.js";
import { informationOutline, informationSharp } from "ionicons/icons";
import { extractCustomReport } from "@/helper";
import { colors } from "@/views/components/colors";
Chart.register(
  BarController,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend
);

export default {
  name: "ChartExpenses",
  components: {
    BarChart,
    IonCol,
    IonDatetime,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
  },
  props: {
    customReportId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const fromDate = new Date();
    fromDate.setFullYear(new Date().getFullYear() - 1);
    const rangeFrom = ref(fromDate.toISOString().substring(0, 7));
    const rangeTo = ref(new Date().toISOString().substring(0, 7));
    const lastClickedIndex = ref(null);

    const displayRangeFrom = computed(
      () => rangeFrom.value.substring(0, 7)
    );
    // eslint-disable-next-line no-unused-vars
    const displayRangeTo = computed(
      () => rangeTo.value.substring(0, 7)
    );

    const currentSavePrefCustomReports =
      store.getters["save/currentSavePrefCustomReports"];
    const custom_report_id = toRef(props, "customReportId");

    const chartData = computed(() => {
      const { custom_report, hashedCR } = extractCustomReport(
        custom_report_id.value,
        currentSavePrefCustomReports
      );

      const c = {
        labels: [],
        datasets: [],
        summary_of_each_categories: [],
      };

      const existingCatIds = {}; // มี category id อะไรบ้าง ในช่วงที่สนใจ

      const catsDS = {};

      if (
        store.state.save.report_data &&
        store.state.save.report_data.monthly_summary
      ) {
        const hCategories = store.getters["account/hashedCategories"];
        Object.keys(store.state.save.report_data.monthly_summary).forEach(
          (m) => {
            if (m >= rangeFrom.value && m <= rangeTo.value) {
              // รายชื่อเดือน
              c.labels.push(m);

              Object.keys(
                store.state.save.report_data.monthly_summary[m]
              ).forEach((cat_id) => {
                // สนใจเฉพาะ หมวดหมู่
                if (
                  (!custom_report && hCategories[cat_id]) ||
                  (custom_report && hashedCR[cat_id])
                ) {
                  if (typeof catsDS[cat_id] === "undefined") {
                    catsDS[cat_id] = {};
                  }
                  if (
                    custom_report || // ถ้าผู้ใช้เลือกมาเอง แสดงหมด ไม่ต้องสนว่าติดลบมั้ย
                    store.state.save.report_data.monthly_summary[m][cat_id] < 0 // เอาเฉพาะที่ติดลบ
                  ) {
                    existingCatIds[cat_id] = true;
                    catsDS[cat_id][m] =
                      -store.state.save.report_data.monthly_summary[m][cat_id];
                  }
                }
              });
            }
          }
        );

        let color_i = 0;
        Object.keys(existingCatIds).forEach((cat_id) => {
          const dataset = {
            label: hCategories[cat_id].account_name,
            data: [],
            backgroundColor: custom_report ? hashedCR[cat_id] : colors[color_i],
          };
          color_i = (color_i + 12) % colors.length;
          c.labels.forEach((m) => {
            dataset.data.push(catsDS[cat_id][m] ? catsDS[cat_id][m] : 0);
          });

          // เก็บๆ ผลรวมของแต่ละ category มาก่อน
          c.summary_of_each_categories.push(
            dataset.data.reduce((c, i) => c + i, 0)
          );

          c.datasets.push(dataset);
        });

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        c.summary_of_each_categories.sort((a, b) => b - a); // เรียง มาก->น้อย
      }

      return c;
    });

    const round = (decimal) => Math.round(decimal * 100) / 100;

    // ผลรวม และค่าเฉลี่ย ของแต่ละหมวดหมู่ ที่แสดงในกราฟ
    const overviewOfEach = computed(() =>
      chartData.value.datasets.map((dataset) => {
        const summary = round(dataset.data.reduce((c, i) => c + i, 0));
        const average = round(summary / dataset.data.length);
        return {
          label: dataset.label,
          color: dataset.backgroundColor,
          summary,
          average,
        };
      })
    );

    // จำนวนเดือนที่แสดงในรายงาน
    const numOfMonths = computed(
      () => {
        if (chartData.value.datasets && chartData.value.datasets[0]) {
          return chartData.value.datasets[0].data.length;
        } else {
          return null;
        }
      }
    );

    // เอาไว้แสดงผลรวมของทุกหมวดหมู่ รวม(summary) และ รวม(average)
    const reduceForTotalOfSummaryAndAverage = computed(() => {
      const totals = overviewOfEach.value.reduce(
        (c, i) => {
          return [c[0] + i.summary, c[1] + i.average];
        },
        [0, 0]
      );

      return [round(totals[0]), round(totals[1])];
    });
    const totalSummary = computed(
      () => reduceForTotalOfSummaryAndAverage.value[0]
    );
    const totalAverage = computed(
      () => reduceForTotalOfSummaryAndAverage.value[1]
    );

    const openMonthModal = async (month) => {
      const modal = await modalController.create({
        component: ModalChartMonthZoomExpense,
        componentProps: {
          month,
          customReportId: custom_report_id.value,
        },
      });
      return modal.present();
    };

    return {
      custom_report_id,
      rangeFrom,
      rangeTo,
      displayRangeFrom,
      displayRangeTo,
      chartData,
      overviewOfEach,
      numOfMonths,
      totalSummary,
      totalAverage,
      options: {
        plugins: {
          legend: {
            position: "bottom",
            align: "start",
            labels: {
              filter: (legend, chartData) => {
                const top = 21; // เอา 21 หมวดหมู่สูงสุด
                return (
                  chartData.summary_of_each_categories.length < top ||
                  chartData.datasets[legend.datasetIndex].data.reduce(
                    (c, i) => c + i,
                    0
                  ) >= chartData.summary_of_each_categories[top - 1]
                );
              },
              sort: (a, b, chartData) => {
                return (
                  chartData.datasets[b.datasetIndex].data.reduce(
                    (c, i) => c + i,
                    0
                  ) -
                  chartData.datasets[a.datasetIndex].data.reduce(
                    (c, i) => c + i,
                    0
                  )
                );
              },
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        onClick: async (event, targets) => {
          if (targets.length > 0) {
            if (lastClickedIndex.value !== targets[0].index) {
              lastClickedIndex.value = targets[0].index;
            } else {
              // กดอีก ให้เปิด dialog
              await openMonthModal(
                chartData.value.labels[lastClickedIndex.value]
              );
            }
          }
        },
      },
      // icons
      helpOutline: informationOutline,
      helpSharp: informationSharp,
    };
  },
};
</script>

<style scoped>
table.cell-padding tr th,
table.cell-padding tr td {
  padding: 5px;
}
</style>
