<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>รายงาน</ion-title>
        <ion-select v-model="selected_chart" slot="end" interface="popover">
          <ion-select-option value="expenses">รายจ่าย</ion-select-option>
          <ion-select-option value="P&L">P&L</ion-select-option>
          <ion-select-option value="assets">ทรัพย์สิน</ion-select-option>
          <ion-select-option
            v-for="cr in currentSavePrefCustomReports"
            :key="cr.id"
            :value="cr.id"
          >
            {{ cr.name }}
          </ion-select-option>
        </ion-select>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <component :is="chartComponentIs" v-bind="{customReportId: selected_chart}"></component>
    </ion-content>
  </ion-page>
</template>

<script typeof="ts">
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";
import ChartAssets from "./charts/ChartAssets.vue";
import ChartExpenses from "./charts/ChartExpenses.vue";
import ChartProfitAndLoss from "./charts/ChartProfitAndLoss.vue";

export default {
  name: "Report",
  components: {
    // Our charts
    ChartAssets,
    ChartExpenses,
    ChartProfitAndLoss,
    // ionic components
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonMenuButton,
    IonSelect,
    IonSelectOption,
  },
  props: {
    chart: {
      type: String,
      default: "expenses"
    },
  },
  setup(props) {
    const store = useStore();

    const selected_chart = ref(props.chart);

    const chartComponentIs = computed(() => {
      switch (selected_chart.value) {
        case "assets":
          return "chart-assets";
        case "expenses":
          return "chart-expenses";
        case "P&L":
          return "chart-profit-and-loss";
        default:
          return "chart-expenses";
      }
    });

    const currentSavePrefCustomReports = computed(() => store.getters["save/currentSavePrefCustomReports"]);

    const fetchReport = async () => {
      await store.dispatch("save/loadReportData");
    };

    onMounted(async () => {
      await fetchReport();
    });

    return {
      selected_chart,
      chartComponentIs,
      currentSavePrefCustomReports,
    };
  },
};
</script>

<style scoped></style>
