<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>รายจ่าย</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="close">
            <ion-icon :ios="closeOutline" :md="closeSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col class="ion-text-end">
            <ion-button color="secondary" @click="prevMonth">
              <ion-icon
                :ios="chevronBackOutline"
                :md="chevronBackSharp"
              ></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col class="ion-text-center">
            <ion-text>{{ localMonth }}</ion-text>
          </ion-col>
          <ion-col>
            <ion-button
              color="secondary"
              @click="nextMonth"
              :disabled="!canNextMonth"
            >
              <ion-icon
                :ios="chevronForwardOutline"
                :md="chevronForwardSharp"
              ></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row v-if="expense_summary">
          <ion-col class="ion-text-center">
            รวม {{ number_format(expense_summary) }}
          </ion-col>
        </ion-row>
      </ion-grid>
      <pie-chart :chart-data="chartData" :options="chartOptions"></pie-chart>
    </ion-content>
  </ion-page>
</template>

<script typeof="ts">
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import {
  chevronBackOutline,
  chevronBackSharp,
  chevronForwardOutline,
  chevronForwardSharp,
  closeOutline,
  closeSharp,
} from "ionicons/icons";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { PieChart } from "vue-chart-3";
import { Chart, PieController, ArcElement, Tooltip } from "chart.js";
import { extractCustomReport, numberFormatComma } from "../../../../helper.js";
import { colors } from "../../../components/colors.js";
Chart.register(PieController, ArcElement, Tooltip);

export default {
  name: "ModalChartMonthZoomExpense",
  components: {
    // chart.js
    PieChart,
    // Ionic Components
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
  },
  props: {
    month: {
      required: true,
      type: String,
      validator: (value) => value.match(/^\d{4}-\d{2}$/),
    },
    customReportId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const month = ref(props.month);

    const custom_report_id = ref(props.customReportId);

    const currentSavePrefCustomReports = store.getters["save/currentSavePrefCustomReports"];
    const chartData = computed(() => {
      const { custom_report, hashedCR } = extractCustomReport(custom_report_id.value, currentSavePrefCustomReports);

      const c = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };

      if (
        store.state.save.report_data &&
        store.state.save.report_data.monthly_summary &&
        store.state.save.report_data.monthly_summary[month.value]
      ) {
        let color_i = 0;
        const hCategories = store.getters["account/hashedCategories"];
        Object.keys(
          store.state.save.report_data.monthly_summary[month.value]
        ).forEach((cat_id) => {
          // interest only categories
          if (
            (!custom_report &&
              hCategories[cat_id] &&
              store.state.save.report_data.monthly_summary[month.value][cat_id] < 0)
            ||
            (custom_report && hashedCR[cat_id])
          ) {
            c.labels.push(
              hCategories[cat_id].parentString +
                hCategories[cat_id].account_name
            );
            c.datasets[0].data.push(
              store.state.save.report_data.monthly_summary[month.value][cat_id]
            );
            c.datasets[0].backgroundColor.push(
              custom_report ? hashedCR[cat_id] : colors[color_i]
            );
            color_i = (color_i + 12) % colors.length;
          }
        });

        const indices = [...c.datasets[0].data.keys()];
        indices.sort((a, b) => c.datasets[0].data[a] - c.datasets[0].data[b]);
        c.datasets[0].data = indices.map((i) => c.datasets[0].data[i]);
        c.labels = indices.map((i) => c.labels[i]);
      }

      return c;
    });

    const expense_summary = computed(() => {
      if (
        store.state.save.report_data &&
        store.state.save.report_data.monthly_summary &&
        store.state.save.report_data.monthly_summary[month.value]
      ) {
        const { custom_report, hashedCR } = extractCustomReport(custom_report_id.value, currentSavePrefCustomReports);
        const hCategories = store.getters["account/hashedCategories"];
        let summary = 0;
        Object.keys(
          store.state.save.report_data.monthly_summary[month.value]
        ).forEach((cat_id) => {
          // interest only categories
          if (
            (!custom_report &&
              hCategories[cat_id] &&
              store.state.save.report_data.monthly_summary[month.value][cat_id] < 0)
            ||
            (custom_report && hashedCR[cat_id])
          ) {
            summary +=
              store.state.save.report_data.monthly_summary[month.value][cat_id];
          }
        });
        return summary;
      }

      return null;
    });

    const canNextMonth = computed(() => {
      const current = new Date().toISOString().substr(0, 7);
      return month.value < current;
    });

    const close = async () => {
      await modalController.dismiss();
    };

    const changeMonth = (interval) => {
      const d = new Date(`${month.value}-01`);
      d.setMonth(d.getMonth() + interval, 1);
      month.value = d.toISOString().substr(0, 7);
    };
    const nextMonth = () => changeMonth(1);
    const prevMonth = () => changeMonth(-1);

    return {
      // props
      localMonth: month,
      // computed
      chartData,
      chartOptions: {
        plugins: {
          legend: {
            position: "bottom",
            align: "start",
          },
        },
      },
      expense_summary,
      canNextMonth,
      // methods
      close,
      nextMonth,
      prevMonth,
      number_format: numberFormatComma,
      // icons
      closeOutline,
      closeSharp,
      chevronBackOutline,
      chevronBackSharp,
      chevronForwardOutline,
      chevronForwardSharp,
    };
  },
};
</script>

<style scoped></style>
