<template>
  <ion-grid>
    <ion-row>
      <ion-col> สินทรัพย์ทั้งหมด </ion-col>
      <ion-col>
        <ion-text>
          {{ number_format(saveBalance) }}
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <pie-chart :chart-data="assetsBalanceChartData" :options="chartOptions"></pie-chart>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script typeof="ts">
import { Chart, PieController, ArcElement, Tooltip } from "chart.js";
import { PieChart } from "vue-chart-3";
import { useStore } from "vuex";
import { computed } from "vue";
import { numberFormatComma } from "../../../../helper.js";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/vue";
import { colors } from "../../../components/colors.js";
Chart.register(PieController, ArcElement, Tooltip);

export default {
  name: "ChartAssets",
  components: {
    // chart.js
    PieChart,
    // ionic
    IonText,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    const store = useStore();

    const saveBalance = computed(() => store.getters["save/saveBalance"]);
    const assetsBalanceChartData = computed(() => {
      const r = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };

      if (store.state.save.report_data) {
        if (store.state.save.report_data.assets) {
          const c = colors;
          let i = 0;
          const assets = [...store.state.save.report_data.assets];
          assets.sort((a, b) => b.balance - a.balance);
          assets.forEach((a) => {
            if (a.balance > 0) {
              r.labels.push(a.name);
              r.datasets[0].data.push(a.balance);
              r.datasets[0].backgroundColor.push(c[i]);
              i = (i + 12) % c.length;
            }
          });
        }
      }

      return r;
    });

    return {
      // methods
      number_format: numberFormatComma,
      // computed
      saveBalance,
      assetsBalanceChartData,
      chartOptions: {
        plugins: {
          legend: {
            position: "bottom",
            align: "start",
          },
        },
      },
    };
  },
};
</script>

<style scoped></style>
